import * as React from "react"

/**
 * Main index page.
 *
 * @returns {React.FunctionComponent} loading or redirect
 */
const Index: React.FunctionComponent = (): React.ReactElement => {
  if (typeof window !== "undefined") {
    window.location.href = "/app/login"
  }
  return <>loading...</>
}

export default Index
